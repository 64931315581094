// This file uses the FingerprintJS library to handle fingerprinting

import FingerprintJS from '@fingerprintjs/fingerprintjs'

document.addEventListener("DOMContentLoaded", async () => {
  try {
    if (document.cookie.includes("fingerprint")) {
      return;
    }
    const fpPromise = await FingerprintJS.load()

    const result = await fpPromise.get()

    document.cookie = `fingerprint=${result.visitorId}; expires=${expiryDate().toUTCString()}; path=/`;
  } catch (error) {
    console.error("Error getting or storing fingerprint:", error);
  }
});


const expiryDate = () => {
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + 7);
  return expiryDate;
}

